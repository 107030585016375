<template>
	<div class="cursor-pointer">
		<input type="file" :id="idFile" :accept="accepted" v-on:change="onFileChanged" ref="file" class="d-none" v-if="!disabledFile" multiple>
		<div :class="{'bg-gray-light p-3 overflow-hidden h-100 w-100':true, 'rounded border-dashed':!circleDF, 'rounded-circle':circleDF, 'd-flex align-items-center justify-content-center':limit<=1, 'd-block p-3':limit>1}" v-on:click="onSelectFile" @drop.prevent="onFileChanged" @dragover.prevent>
			<div class="form-row m-0 align-items-stretch justify-content-center w-100" v-if="files.length>0 && limit!=1">
				<div class="col-md-12 text-center">
					<div class="row">
						<div class="col-lg-3 mb-2" v-for="(file, index) in files" v-on:click.stop="" :key="index">
							<div :class="{'card h-100 text-white':true, 'position-relative bg-light':(file.dataURL || file.ARCHIVO), 'bg-primary':!(file.dataURL || file.ARCHIVO)}">
								<div v-if="file.dataURL && !file.ARCHIVO" class="card-body p-0">
									<img class="img-fluid img-upload" :src="file.dataURL" v-if="isImage(file.dataURL)">
									<div  class="text-dark" v-else>
										<font-awesome-icon :icon="faFile" class="fs-1"/>
										<p>{{file.NAME_FILE}}</p>
									</div>
								</div>
								<div v-if="file.ARCHIVO" class="card-body p-0">
									<img class="img-fluid img-upload" :src="file.ARCHIVO" v-if="isImage(file.ARCHIVO)">
									<div class="text-dark" v-else>
										<font-awesome-icon :icon="faFile" class="fs-1"/>
										<p>{{file.NAME_FILE}}</p>
									</div>
								</div>
								<div :class="{'card-body':true, 'card-body-hover':file.dataURL || file.ARCHIVO}">
									<div class="d-flex align-items-center flex-column align-items-end h-100">
										<p class="card-title" v-html="kb(file.file.size)" v-if="file.file">
										</p>
										<p class="card-title" v-html="kb(file.SIZE_FILE)" v-else></p>
										<p class="card-text text-truncate w-100 my-auto">
											<small class="text-truncate" v-if="file.file">{{file.file.name}}</small>
											<small class="text-truncate" v-else>{{file.NAME_FILE}}</small>
										</p>
										<div class="d-flex justify-content-center">
											<button type="button" class="btn btn-outline-light btn-block text-truncate me-3" v-on:click="removeImage(index)" v-if="!disabledFile">
												<font-awesome-icon :icon="faTrash" />
											</button>
											<button class="btn btn-outline-light btn-block text-truncate" v-on:click="viewImage(file.ARCHIVO, file.NAME_FILE)" type="button">
												<font-awesome-icon :icon="faSearch" />
											</button>
										</div>
									</div>
								</div>
								<div class="card-body card-body-details" v-if="file.progress>0 || (file.errors && file.errors.file)">
									<ProgressBar :progress="file.progress" progress-complete-text="Archivo subido" class="mb-2"></ProgressBar>

									<span v-if="file.errors && file.errors.file">
										{{file.errors.file[0]}}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="text-center position-relative hover-show h-100 w-100" v-for="(file, index) in files" :key="index" v-on:click.stop="" v-else>
				<img class="h-100 w-100 img-contain" :src="file.dataURL" v-if="file.dataURL && !file.ARCHIVO" v-on:click.prevent="viewImage(file.ARCHIVO, file.NAME_FILE)">
				<img class="h-100 w-100 img-contain" :src="file.ARCHIVO" v-if="file.ARCHIVO && isImage(file.ARCHIVO)" v-on:click.prevent="viewImage(file.ARCHIVO, file.NAME_FILE)">
				<font-awesome-icon :icon="faFile" class="fs-1 mt-2" v-else/>

				<a href="#" class="text-white text-truncate footer-hover" v-on:click.prevent.stop="removeImage(index)" v-if="!disabledFile">
					<font-awesome-icon :icon="faTimesCircle" class="wh-15px"/>
				</a>

				<span v-if="errors" class="invalid-tooltip d-block">
					{{errors}}
				</span>

			</div>
			<div class="text-center text-muted position-relative" v-if="!disabledFile && files.length<=0">
				<font-awesome-icon :icon="faFile" class="fs-1"/>
				<p v-if="limit>0 && files.length > limit">Solo tiene límite para {{limit}} archivos</p>
				<p v-else-if="titleDF">{{titleDF}}</p>
				<button class="btn btn-succes" v-on:click.stop="uploadFiles" v-if="existsErrors">Subir archivos</button>
			</div>
		</div>
		<PreviewFile :url="url" :title="titulo" ref="preview"></PreviewFile>
		<span v-if="errors" class="invalid-tooltip d-block">
			{{errors}}
		</span>
	</div>
</template>
<script>
	import PreviewFile from '@/components/PreviewFile.vue'
	import ProgressBar from '@/components/ProgressBar.vue'
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faFile, faTimesCircle, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'

	export default {
		components: {
			PreviewFile,
			ProgressBar,
			FontAwesomeIcon
		},
		emits: ['update:modelValue'],

		props: ['accept', 'id', 'modelValue', 'disabled', 'limitFile', 'tipo', 'adjuntos', 'codigo', 'title', 'icon', 'circle', 'drop'],

		data(){
			return {
				files: [],
				url: '',
				titulo: '',
				errors: '',
				uploadFile: 0,
				faFile,
				faTimesCircle,
				faSearch,
				faTrash
			}
		},

		mounted(){
			this.files = this.adjuntosDf || []
		},

		methods: {
			onSelectFile(){
				if (this.limit > 0 && this.files.length >= this.limit && !this.dropDF) {
					this.$toast.error(`Solo tiene límite para ${this.limit} archivos`)
					return
				}
				if (!this.disabledFile) {
					this.$nextTick(() => {
						this.$refs.file.click()
					})
				}
			},
			onFileChanged(event){
				if (this.disabledFile) {return}
				var files = event.target.files || event.dataTransfer.files
				var ini = this.files.length || 0
				var total = (files.length || 0) + ini
				this.uploadFile = ini

				var iniFile = 0

				for (var i = ini; i < total; i++) {
					if (this.limit > 0 && this.files.length >= this.limit && !this.dropDF) {
						this.$toast.error(`Solo tiene límite para ${this.limit} archivos`)
						return
					}
					var index = this.files.push({
						id: i,
						file: files[iniFile],
						dataURL: null,
						CODIGO_RELACION: '',
						CODIGO_ADJUNTO: '',
						TIPO_ADJUNTO: '',
						CORRELATIVO: '',
						ARCHIVO: '',
						NAME_FILE: '',
						EXTENSION_FILE: '',
						SIZE_FILE: '',
						CREADO_POR: '',
						CREADO_DESDE: '',
						FECHA_ACTUALIZACION: '',
						FECHA_CREACION: '',
						progress: 0,
						errors: {}
					}) - 1
					if (/\.(jpe?g|png|gif|jfif|svg)$/i.test(files[iniFile].name)) {
						this.readURL(files[iniFile], index)
					}
					iniFile = iniFile + 1
				}
				this.uploadFiles()
				//this.getImagePreviews()
			},
			uploadFiles(){
				var file = this.files.filter(x => x.id == this.uploadFile)[0]

				if (!file) {
					return
				}

				this.errors = ''
				this.$store.commit('showLoading')

				var formData = new FormData()
				formData.append(`file`, file.file)
				formData.append(`tipo`, this.tipoDf)
				formData.append(`codigo`, this.codigoDf)
				if(this.dropDF){
					formData.append(`drop`, this.dropDF)
				}
				
				this.$store.dispatch('storeAdjunto', formData)
				/*axios.post('adjuntos', , {	
				    onUploadProgress: function(e) {
				    	this.files[this.fileUpload].progress = (e.loaded / e.total) * 100
				    }.bind(this)
				})*/
				.then((response) => {
					this.$toast.info('Archivo subido correctamente.')
					file.CODIGO_RELACION = response.data.CODIGO_RELACION
					file.CODIGO_ADJUNTO = response.data.CODIGO_ADJUNTO
					file.TIPO_ADJUNTO = response.data.TIPO_ADJUNTO
					file.CORRELATIVO = response.data.CORRELATIVO
					file.ARCHIVO = response.data.ARCHIVO
					file.NAME_FILE = response.data.NAME_FILE
					file.EXTENSION_FILE = response.data.EXTENSION_FILE
					file.SIZE_FILE = response.data.SIZE_FILE
					file.CREADO_POR = response.data.CREADO_POR
					file.CREADO_DESDE = response.data.CREADO_DESDE
					file.FECHA_ACTUALIZACION = response.data.FECHA_ACTUALIZACION
					file.FECHA_CREACION = response.data.FECHA_CREACION
					this.files[file.id] = file
					var files = this.files.filter(x => x.CODIGO_ADJUNTO >= 0)
					this.$emit('update:modelValue', files.map(x=>x.CODIGO_ADJUNTO))
				}).catch((error) => {
					this.files[file.id].progress = 0
					if (error.response) {
						this.files.splice(file.id, 1)
						
						if (typeof error.response.data.file != 'undefined') {
							this.$toast.error(error.response.data.file[0])
							this.errors = error.response.data.file[0]
						}else{
							this.$toast.error('Error al subir archivo')
						}
					}else{
						this.$toast.error('Error al subir archivo')
					}
				}).then(()=>{
					this.uploadFile++
					this.uploadFiles()
				})
			},
			readURL(file, index) {
				var reader = new FileReader()
				reader.onload = (e) => {
					this.files[index].dataURL = e.target.result
				}
				reader.readAsDataURL(file)
			},
			viewImage: function (data, title) {
				this.url = data
				this.titulo = title
				this.$nextTick(() => {
					this.$refs.preview.open()
				})
			},

			removeImage: function (index) {
				if (this.files[index].CODIGO_ADJUNTO>0) {
					this.$store.dispatch('deleteAdjunto', this.files[index].CODIGO_ADJUNTO)
					.then(response => {
						this.files.splice(index, 1)
						this.$toast.info('Archivo eliminado')
						var files = this.files.filter(x => x.CODIGO_ADJUNTO >= 0)
						this.$emit('update:modelValue', files.map(x=>x.CODIGO_ADJUNTO))
						return response
					}).catch(error => {
						this.$toast.error('Error al eliminar archivo')
						return error
					})
				}else{
					this.files.splice(index, 1)
					var files = this.files.filter(x => x.CODIGO_ADJUNTO >= 0)
					this.$emit('update:modelValue', files.map(x=>x.CODIGO_ADJUNTO))
				}
			},
			kb(val){
				// Kilobyte
				if (val >= 1000) {
					var kb = (val || 1000)/1000

					// Megabyte
					if (kb >= 1000) {
						var mb = kb/1000

						// Gigabyte
						if (mb >= 1000) {
							var gb = mb/1000
							return '<strong>'+gb.toFixed(1)+'</strong> GB'
						}
						return '<strong>'+mb.toFixed(1)+'</strong> MB'
					}
					return '<strong>'+kb.toFixed(1)+'</strong> KB'
				}

				// Byte
				return '<strong>'+val.toFixed(1)+'</strong> B'
			},
			isImage(val){
				return(val.match(/\.(jpe?g|png|gif|jfif|svg)$/) != null)
			}
		},

		computed: {
			accepted(){
				return this.accept
			},

			idFile(){
				return this.id
			},
			disabledFile(){
				return !!this.disabled
			},

			limit(){
				return this.limitFile || 1
			},

			dropDF(){
				return this.drop
			},

			existsErrors(){
				return false
			},

			tipoDf(){
				return this.tipo
			},

			valueDf(){
				return this.modelValue
			},

			adjuntosDf(){
				return this.adjuntos || []
			},

			codigoDf(){
				return this.codigo
			},

			titleDF(){
				if (this.title != null) {
					return this.title
				}
				return  'Arrastra las fotos aquí o haz clic para subirlas'
			},

			iconSVG(){
				if (this.icon) {
					return this.icon
				}
				return 'UploadIcon'
			},

			circleDF(){
				if (this.circle != null) {
					return true
				}
				return false
			}
		},

		watch: {
			valueDf(val){
				if (val.length == 0) {
					this.files = []
				}
			},

			adjuntosDf(val){
				this.files = val || []
			},
		}
	}
</script>
<style>
	.img-upload {
		min-height: 130px;
		object-fit: cover;
		object-position: center;
	}

	.card-body-hover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.5);
		display: none;
	}

	.footer-hover {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0px;
		background-color: rgba(0,0,0,0.5);
		display: none;
	}

	.card-body-details {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.5);
	}

	.card:hover > .card-body-details {
		display: none;
	}

	.card:hover > .card-body-hover{
		display: block;
	}

	.hover-show:hover > .footer-hover{
		display: block;
	}

	.border-dashed {
		border-style: dashed;
	}
</style>