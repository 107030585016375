 <template>
	<div class="modal p-0" data-backdrop="static" role="dialog" aria-labelledby="showPreviewLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header align-items-center py-1">
					<div class="text-truncate">
						{{titleDF}}
					</div>
					<div>
						<button type="button" class="btn btn-link btn-sm" v-on:click="download(urlDF,titleDF)" v-if="urlView">
							<font-awesome-icon :icon="faDownload"/>
						</button><button type="button" class="btn btn-link btn-sm" v-on:click="print()" v-if="false">
							v-if="urlView"
							<font-awesome-icon :icon="faPrint"/>
						</button>
						<button type="button" class="btn btn-link btn-sm" data-bs-dismiss="modal" aria-label="Close">
							<font-awesome-icon :icon="faTimesCircle"/>
						</button>
					</div>
				</div>
				<div class="modal-body h-full-modal">
					<div v-if="urlView" class="h-100 w-100" style="text-align: center;">
						<img :src="urlDF" v-if="isImage" class="img-fluid" style="height: 100%;">
						<iframe class="w-100 h-100 border-0" className="doc" :src="urlView" v-else></iframe>
					</div>
					<div v-if="!urlView">
						<p class="text-muted">No se puede visualizar el archivo</p>
					</div>
					<embed class="d-none" :src="urlDF" ref="iframe" v-if="urlDF" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	var FileDownload = require('js-file-download')
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faDownload, faPrint, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
	export default {
		props: ['url', 'title'],
		components: {
			FontAwesomeIcon
		},

		data(){
			return {
				faDownload,
				faPrint,
				faTimesCircle
			}
		},

		mounted(){
			//$(this.$el).modal()
			//var vm = this
			//$(this.$el).on('hidden.bs.modal', function (e) {})
		},

		created() {
			
		},

		methods: {
			//
			open(){
				$(this.$el).modal('show')
			},

			hide(){
				$(this.$el).modal('hide')
			},

			download(uri, name){
				this.$store.dispatch('download', {url: uri}).then(res => {
					FileDownload(res.data, name)
				})
			},

			print(){
				this.$nextTick(() => {
					this.$refs.iframe.contentWindow.print()
				})
			}
		},

		computed: {
			urlView(){
				if (this.url) {
					return `https://docs.google.com/gview?url=${this.url}&embedded=true`
				}
				return null
			},

			urlDF(){
				return this.url || ''
			},

			titleDF(){
				return this.title || ''
			},

			isImage(){
				return(this.urlDF.match(/\.(jpeg|jpg|gif|png)$/) != null)
			}
		},
	}
</script>