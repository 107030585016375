<template>
	<div class="progress">
		<div :class="classBg" role="progressbar" aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" :style="{width: getProgress}">
			<span v-if="progress < 100 && showProgressText">
				{{getProgress}}
			</span>
			<span v-if="progress >= 100 && showProgressText">{{progressCompleteText || getProgress}}</span>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			progress: {
				default: 0,
				type: Number
			},
			showProgressText: {
				default: true,
				type: Boolean
			},
			progressCompleteText: {
				default: null,
				type: String
			},
			background: {
				default: '',
				type: String
			}
		},

		computed: {
			getProgress() {
				return Math.round(this.progress) + "%";
			},

			classBg(){
				return 'progress-bar progress-bar-striped progress-bar-animated ' + (this.background || '')
			}
		},
		watch: {
			progress(progress) {
				this.currentProgress = progress;
			}
		},
		data() {
			return {
				currentProgress: 0
			}
		}
	}
</script>